<template>
  <b-card>  
    <!-- form -->
    <b-form>
      <validation-observer ref="simpleRules">
        <div class="d-flex">
          <feather-icon icon="UserIcon" size="19" />
          <h4 class="mb-0 ml-50">Settlement Information</h4>
        </div>
            <b-card v-if="userData.roles[0].name == 'ISP Admin'">  
        <h5 class="text-capitalize mb-75">Company Account Name</h5>
        <b-card-text>
          {{ settlementData.company_ac_name }}
        </b-card-text>
        <hr />
        <h5 class="text-capitalize mb-75">Bank Name</h5>
        <b-card-text>
          {{ settlementData.merchant_bank_name }}
        </b-card-text>
        <hr />
        <h5 class="text-capitalize mb-75">Bank Branch Address</h5>
        <b-card-text>
          {{ settlementData.bank_address }}
        </b-card-text>
        <hr />
        <h5 class="text-capitalize mb-75">Bank Account Number</h5>
        <b-card-text>
          {{ settlementData.bank_ac_no }}
        </b-card-text>
        <hr />
        <h5 class="text-capitalize mb-75">Bank Routing Number</h5>
        <b-card-text>
          {{ settlementData.bank_routing_no }}
        </b-card-text>
        <hr />
        <h5 class="text-capitalize mb-75">Gateway Processing Free</h5>
        <b-card-text>
          {{ settlementData.gateway_processing_free }}
        </b-card-text> 
       </b-card> 
        <b-row
          v-if="
            userData.roles[0].name == 'Master Admin' ||
            userData.roles[0].name == 'Admin'
          "
        >
          <!-- Invoice date -->
          <b-col sm="6" cols="12" md="4">
            <b-form-group
              label="Company Account Name"
              label-for="company_ac_name"
            >
              <b-form-input
                id="company_ac_name"
                v-model="settlementData.company_ac_name"
                placeholder="Enter Company Account Name"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice date -->
          <!-- Invoice From date -->
          <b-col md="6">
            <b-form-group label="Bank Name" label-for="merchant_bank_name">
              <b-form-input
                id="merchant_bank_name"
                v-model="settlementData.merchant_bank_name"
                placeholder="Enter Bank Name"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice From date -->
          <!-- Invoice To date -->
          <b-col md="6">
            <b-form-group label="Bank Branch Address" label-for="bank_address">
              <b-form-textarea
                id="bank_address"
                v-model="settlementData.bank_address"
                rows="4"
                placeholder="Your bank branch address"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice To date -->
          <!-- Invoice last_payment_date date -->
          <b-col md="6">
            <b-form-group label="Bank Account Number" label-for="bank_ac_no">
              <b-form-input
                id="bank_ac_no"
                v-model="settlementData.bank_ac_no"
                placeholder="Enter Bank Account Number"
              />
            </b-form-group>
          </b-col>
          <!--/ Invoice last_payment_date date -->
          <!-- Country -->
          <b-col md="6">
            <b-form-group
              label="Bank Routing Number"
              label-for="bank_routing_no"
            >
              <b-form-input
                id="bank_routing_no"
                v-model="settlementData.bank_routing_no"
                placeholder="Enter Bank Routing Number"
              />
            </b-form-group>
          </b-col>
          <!--/ Country -->
          <b-col md="6">
            <b-form-group
              label="Gateway Processing Free"
              label-for="gateway_processing_free"
            >
              <b-form-input
                id="gateway_processing_free"
                v-model="settlementData.gateway_processing_free"
                placeholder="Enter Gateway Processing Free"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="validationForm"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mt-1"
              variant="outline-secondary"
              @click.prevent="resetForm"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BFormTextarea,
  BFormCheckbox,
  BCard,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import { checkRes } from "@core/services/helper";
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  components: {
    BCard,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    settlementData: Object,
  },
  data() {
    return {
      settlementDataFrom: true,
      userData: JSON.parse(localStorage.getItem("userData")),
      ispId:this.$route.query.isp_id
    };
  },
  methods: {
    resetForm() {
      this.settlementData = this.settlementData;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .updateIspProfileInfo({
              isp_id:this.ispId,
              settlementData: this.settlementDataFrom,
              company_ac_name: this.settlementData.company_ac_name ?? null,
              merchant_bank_name:
                this.settlementData.merchant_bank_name ?? null,
              bank_address: this.settlementData.bank_address,
              bank_ac_no: this.settlementData.bank_ac_no,
              bank_routing_no: this.settlementData.bank_routing_no,
              gateway_processing_free:
                this.settlementData.gateway_processing_free,
            })
            .then((response) => {
              if (checkRes(response.status) && response.data.data) {
                this.$router
                  .push({ name: "account-setting" })
                  .catch((error) => {
                    this.toastMessage("success", "Profile Info", response);
                  });
              } else {
                this.$router
                  .push({ name: "account-setting" })
                  .then(() => {
                    this.toastMessage("warning", "Profile Info", response);
                  })
                  .catch((error) => {
                    this.toastMessage("danger", "Profile Info", response);
                  });
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
